$(function () {
    void 0;

    var utility = new cemf.js.Utility();
    utility.startApp();


    /* Verificando Existencia de usuario em tempo Real*/
    var nick_interval = null;
    $("#nickname").on("keyup",function(){
        clearInterval(nick_interval);
        nick_interval = setTimeout(function(){
            checked($('#nickname'));
        },1000);
    });

    /* Fazendo comunicação com Controlador */
    function checked(selector){
        var dataset = {"nickname":selector.val()};
        void 0;
        $.post(selector.data("push"), dataset, function (response) {

            void 0;
            if (response.reload) {
                window.location.reload();
            }
            if(response.message){
                $('.ajax_request')
                    .removeClass("icon-ban icon-check")
                    .addClass((response.error) ? "icon-ban" : "icon-check")
                    .text((response.message));
            }
        }, "json");
    }

});


$(function () {
    void 0;
    var utility = new cemf.js.Utility();


    /***************
     * FILTER OFFER CLIENT
     ***************/
    $("[data-filter='offer-client']").on('click', function () {
        var current_top = $('main.kg-content').position().top;
        var next_top = $("[data-filter-final]").offset().top + 10;
        if(current_top >= next_top){
            next_top = 61;
        }
        $('main.kg-content').animate({
            top: next_top + 'px'
        }, 400, function () {
            $("#image-filter")
                .attr('src', '../shared/images/icons/' + ((current_top < next_top) ? 'ic_arrow_upward_white_18dp.png' : 'ic_filter_list_white_18dp.png'));

        });
        $(this).toggleClass('rotateIn');
    });


    /***************
     * CHANGE PASSWORD PROFILE
     ***************/
    $("#change_password").on("click", function () {
        $('.register_group_password').toggleClass('none');
    });

    /*******************
     *  MASCARAS E ACAO
     *  *****************/

    var citymodifyCEP = '';
    $("#zipcode").mask("00000-000",utility.searchZipCode($(this).val()));
    $("#cell").mask("(00) 00000-0000");
    $("#cpf").mask("000.000.000-00");
    $("#cardNumber").mask("0000 0000 0000 0000");


    /********************
     *   MENU CLOSE/OPEN
     */

    $(".j_menu_mobile_close a").on("click",function (e) {
        e.preventDefault();
        void 0;
        $(".kg-menu-action-mobile").animate({
            right:"-400px"
        },200,function(){

        })
    })

    $(".menu-hamburg").on("click",function (e) {
        e.preventDefault();
        $(".kg-menu-action-mobile").animate({
            right:"0"
        },200,function(){

        })
    })



});


/**************************
 *  JAVASCRIPT RESPONSAVEL PELO MOTOR DE BUSCA
 */
void 0;
$(function () {
    var interval;
    var timeRequest = 2000; //tempo para fazer post após selecionar filtros

    var utility = new cemf.js.Utility();


    $("[data-engine-lm]").on('click', function (e) {
        e.preventDefault();
        var quantity = $(this).data('engine-lm');
        void 0;
        $("[data-engine-lm-loading]").removeClass('none');
        $("[data-engine-lm-image]").addClass('none');
        $("[data-engine-lm-info]").text('Carregando...');

        requestEngine();
        //resquestFilter();
    });

    /*********************************
     * FAZENDO REQUISIÇÃO COM SERVIDOR
     **********************************/
    function requestEngine($params) {
        $.post(utility.url_current() + "/push", $params, function (response) {
            void 0;
            void 0;
            void 0;

            //message
            if (response.message) {
                utility.ajaxMessage(response.message, utility.ajaxResponseBaseTime);
            }
            if (response.offers) {
                if(response.type == 'double'){
                    loadCardDouble(response.offers);
                }else{
                    loadCard(response.offers)
                }

            }
            if (response.finish) {
                void 0;
                $("[data-engine-lm]").fadeOut('fast');
            }
        }, "json")
            .done(function() {
                void 0;
            })
            .fail(function() {
                utility.ajaxMessage(utility.ajaxResponseRequestError, 5);
            })
            .always(function() {
                $("[data-engine-lm-loading]").addClass('none');
                $("[data-engine-lm-image]").removeClass('none');
                $("[data-engine-lm-info]").text('Carregar mais');
            });

    }

    /*********************************
     * CARREGANDO CARDS NA INTERFACE
     **********************************/
    function loadCardDouble($cards) {
        void 0;
        $cards.forEach(function (element, index) {
            $(utility.createOfferCardDouble(element)).insertBefore("[data-engine-lm]");
        });
    }
    function loadCard($cards) {
        void 0;
        $cards.forEach(function (element, index) {
            $(utility.createOfferCard(element)).insertBefore("[data-engine-lm]");
        });
    }

    /*********************************
     * CRIANDO CARD
     **********************************/
    function createCard($offer) {
       void 0;

    }

    /*********************************
     * CRIANDO O FILTRO
     **********************************/
    $("[data-filter-post]").on("click", function () {

        void 0;
        var $form = $("#form-filter");
        var exist = $form.find("#"+$(this).data("filter-post"));
        if(exist.length > 0){
            void 0;
            return;
        }
        removeInterval();
        var $params = {
            'value': $(this).data("filter-text"),
            'name': $(this).data("filter-post"),
        }
        $form.prepend(createItemFilter($params));
        startInterval();
    });

    $("[data-filter-post-check]").on("click", function () {
        removeInterval();
        var check = $(this).prop("checked");
        var $form = $("#form-filter");
        if (check) {
            var $params = {
                'value': $(this).data("filter-text-check"),
                'name': $(this).data("filter-post-check"),
            }
            $form.prepend(createItemFilter($params));
        } else {
            var item = $form.find('#' + $(this).data("filter-post-check"))[0];
            $(item).remove();
        }
        startInterval();
    });

    function createItemFilter($params) {
        var div = $("<div></div>");
        div
            .addClass("option_item")
            .attr('id', $params.name);

        var input = $("<input/>");
        input
            .addClass("first-upper --black")
            .attr({
                'value': $params.value,
                'type': 'text',
                'name': $params.name
            });
        var span = $("<span>X</span>");
        span.on("click", function () {
            removeInterval();
            div.fadeOut("fast", function () {
                $(this).remove();
                startInterval();
            })
        });
        div.append(input, span);
        return div;
    }

    /*********************************
     * CRIANDO REMOÇÃO DE FILTRO QUANDO CRIADO PELO PHP
     **********************************/
    $("[data-filter-remove]").on('click', function () {
        var $filter = $(this).data("filter-remove");
        removeInterval();
        $("#" + $filter).fadeOut('fast', function () {
            $(this).remove();
            startInterval();
        })
    })

    /*********************************
     * FAZENDO SUBMIT NO FILTRO
     **********************************/

    function resquestFilter() {
        var current_top = $('main.kg-content').position().top;
        void 0;

        var form = $("#form-filter");
        var load = $(".filter_loading");

        form.ajaxSubmit({
            url: form.attr("action"),
            type: "POST",
            dataType: "json",
            beforeSend: function () {
                void 0;
                load.removeClass('none');

            },
            success: function (response) {
                void 0;
                void 0;

                //redirect
                if (response.reload) {
                    void 0;
                    location.reload();
                }

                //redirect
                if (response.redirect) {
                    window.location.href = response.redirect;
                }
                //message
                if (response.message) {
                    utility.ajaxMessage(response.message, utility.ajaxResponseBaseTime);
                }
                if (response.offers) {
                    loadCard(response.offers)
                }
                if (response.finish) {
                    void 0;
                    $("[data-engine-lm]").fadeOut('fast');
                }

            },
            complete: function () {
                void 0;
                load.addClass('none');
                $("[data-engine-lm-loading]").addClass('none');
                $("[data-engine-lm-image]").removeClass('none');
                $("[data-engine-lm-info]").text('Carregar mais');

                if (form.data("reset") === true) {
                    form.trigger("reset");
                }
            },
            error: function () {
                utility.ajaxMessage(utility.ajaxResponseRequestError, 5);
                load.addClass('none');
                $("[data-engine-lm-loading]").addClass('none');
                $("[data-engine-lm-image]").removeClass('none');
                $("[data-engine-lm-info]").text('Carregar mais');
            }
        });
    }


    function startInterval() {
        $(".filter_loading").removeClass('none');
        interval = setTimeout(function () {
            resquestFilter();
            void 0;
        }, timeRequest);
    }

    function removeInterval() {
        clearTimeout(interval);
        void 0;
    }
});